.ChatLayout{
  width: 100vW;
  height: 100vh;
  overflow: hidden;
  .ChatContainer{
    margin-top: 87px;
    .ChatWindow{
      display: flex;
      flex-direction: row;
      height: calc(100vh - 87px);
      .ChatSidebar{
        min-width: 25%;
        //background: red;
        height: 100%;
        box-sizing: border-box;
        border-right: 1px solid $theme-lpurple;
        padding: 32px 32px 0;
        .ChatSidebarTop{
            .headingContainer{
              .headings{
                display: flex;
                align-items: center;
                justify-content: space-between;
                h1{
                  font-weight: 700;
                  font-size: 30px;
                  line-height: 110%;
                  letter-spacing: -0.02em;
                  color: $theme-black;
                  margin-bottom: 0;
                }
                .dropdownContainer{
                  .dropdown-toggle{
                    background: none;
                    border: none;
                    box-shadow: none;
                    outline: none;
                    padding: 0;
                    &:after{
                      content: unset;
                    }
                  }
                  .dropdown-item{
                    svg{
                      margin-right: 10px;
                    }
                    &:hover, &:focus{
                        background-color: transparent;
                    }
                  }
                }
              }
            }
          .searchBarContainer{
            .searchBarLeft{
                margin-top: 13px;
                background: $theme-lpurple;
                border-radius: 6px !important;
                border: none !important;
                border-bottom: none !important;
                min-height: 40px !important;
                height: 40px;

                font-weight: 400;
                font-size: 15px;
                line-height: 22px;
                color: #060836;

                background-image: url("data:image/svg+xml,%3Csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7.5 13.5C10.8137 13.5 13.5 10.8137 13.5 7.5C13.5 4.18629 10.8137 1.5 7.5 1.5C4.18629 1.5 1.5 4.18629 1.5 7.5C1.5 10.8137 4.18629 13.5 7.5 13.5Z' stroke='%233D2570' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M15.4999 15.4999L11.7419 11.7419' stroke='%233D2570' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
                background-repeat: no-repeat;
                background-position: 95% 50%;
                padding: 9px 40px 9px 16px;
                &::placeholder {
                  color: rgba(6,8,54, 0.7);
                }
            }
          }
        }
        .chatTilesContainer{
          margin-top: 26px;
            .tilesList{
              list-style: none;
              padding-left: 0;
              .tileComponent{
                display: flex;
                flex-direction: row;
                .profileImgContainer{
                  position: relative;
                  overflow: hidden;
                  margin-right: 12px;
                  .imgTile{
                    width: 40px;
                    height: 40px;
                    overflow: hidden;
                    position: relative;
                    img{
                      width: 40px;
                      height: 40px;
                      object-fit: cover;
                      object-position: center;
                      border-radius: 100%;
                      overflow: hidden;
                    }
                  }
                }
                .profileDetails{
                  width: 80%;
                  p{
                    font-weight: 400;
                    font-size: 13px;
                    line-height: 20px;
                    color: $theme-black;

                    opacity: 0.7;
                  }
                  .profileDetailsHeader{
                    display: flex;
                    align-items: center;
                    flex-direction: row;
                    justify-content: space-between;
                    width: 100%;
                    .profileName{
                      font-weight: 500;
                      font-size: 16px;
                      line-height: 20px;
                      color: $theme-black;
                      display: block;
                    }
                    .activeStatus{
                      font-weight: 400;
                      font-size: 12px;
                      line-height: 18px;
                      text-align: right;
                      color: $theme-black;
                      opacity: 0.7;
                      display: block;
                    }
                  }

                }
                &.isActive{
                  .profileImgContainer{
                    .imgTile{
                      &:after{
                        content: '';
                        position: absolute;
                        width: 12px;
                        height: 12px;
                        background: $green-active;
                        border: 2px solid #fff;
                        box-sizing: border-box;
                        right: 0;
                        bottom: 0;
                        border-radius: 100%;
                      }
                    }
                  }
                }
              }
            }
        }
      }
      .ChatMainWindow{
        min-width: 75%;
        //background: blue;
        height: 100%;
      }
    }
  }
}