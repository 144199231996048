/*
**********************************************
* Default theme Global styling
**********************************************
*/

html,
body {
  height: 100%;
  position: relative;
}

body {
  position: relative;
  font-family: 'Poppins', sans-serif;
  color: $theme-black;
}
p{
  font-family: 'Poppins', sans-serif;
  color: $theme-black;
  font-weight: 400;
  margin-bottom: 10px;
}
h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6{
  font-family: 'Poppins', sans-serif;
}
a{
  font-family: 'Poppins', sans-serif;
}

::-webkit-scrollbar {
   width: 5px;
  //display: none;
}

::-webkit-scrollbar-track {
  background: $theme-lpurple;
}

::-webkit-scrollbar-thumb {
  background: $theme-purple;
}

::-webkit-scrollbar-thumb:hover {
  background: $theme-purple;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/*
**********************************************
* utility classes
**********************************************
*/
.m-t-10{
  margin-top: 10px;
}
.m-t-20{
  margin-top: 20px;
}
.m-t-30{
  margin-top: 30px;
}
.m-t-40{
  margin-top: 40px;
}
.m-t-50{
  margin-top: 50px;
}
.m-b-10{
  margin-bottom: 10px;
}
.m-b-20{
  margin-bottom: 20px;
}
.m-b-30{
  margin-bottom: 30px;
}
.m-b-40{
  margin-bottom: 40px;
}
.m-b-50{
  margin-bottom: 50px;
}

/*
**********************************************
* Default theme styles
**********************************************
*/
.checkbox{
  .form-check-input{
    outline: none;
    box-shadow: none;
    border-radius: 0;
    transition: all 0.5s ease;
    &:checked {
      background-color: $theme-purple;
      border-color: $theme-purple;
      border-radius: 0;
      transition: all 0.5s ease;
    }
  }
}
/*
**********************************************
* Default buttons
**********************************************
*/
.btn-solid{
  //padding: 13px 102px;
  min-height: 50px;
  background: $theme-purple;
  border: 1px solid $theme-purple;
  border-radius: 100px;
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  display: block;
  color: $theme-white;
  margin-bottom: 12px;
  transition: all 0.5s ease;
  svg{
    font-size: 24px;
    margin-right: 12px;
  }
  &:hover, &:focus, &.active{
    background: transparent;
    border: 1px solid rgba(61, 37, 112, 0.3);
    color: $theme-black;
    font-weight: 500;
    transition: all 0.5s ease;
  }
}
/**
* rounded outline button
**/
.btn-rounded-outline{
  box-sizing: border-box;
  padding: 12px 32px;
  //width: 109px;
  //height: 44px;
  background: $theme-white;
  border: 2px solid $theme-purple;
  border-radius: 100px;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: $theme-purple;
  text-decoration: none;
  transition: all 0.5s ease;
  &:not(:last-child){
    margin-right: 12px;
  }
  &:hover, &:focus, &.active{
    background: $theme-purple;
    color: $theme-white;
    transition: all 0.5s ease;
  }
}

/**
* go back button link
**/
.backToBtn{
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  color: $theme-purple;
  text-decoration: none;
  transition: all 0.5s ease;
  svg{
    margin-right: 8px;
    transition: all 0.5s ease;
  }
  &:hover, &:focus{
    color: $theme-purple;
    svg{
      transform: translateX(-5px);
      transition: all 0.5s ease;
    }
  }
}

/*
**********************************************
* global inputs
**********************************************
*/

/**
  * form-control defaults
  **/
.form-control, .form-select{
  border: none;
  outline: none !important;
  box-shadow: none !important;
  //width: 370px;
  min-height: 48px;
  background-color: #F5F4F8;
  border-radius: 6px 6px 0px 0px !important;
  border-bottom: 1px solid rgba(61, 37, 112, 0.3);
  &:focus{
    background: #F5F4F8;
    border-bottom: 1px solid rgba(61, 37, 112, 0.8);
  }

}
.mb13{
  margin-bottom: 13px;
}
.validation-error{
  font-size: 10px;
  color: red;
  transition: all 0.5s ease;
}

.form-select{
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3E%3C/svg%3E");
}

/**
* Remember checkbox
**/
.formCheckbox{
  display: flex;
  align-items: center;
  .form-check-input{
    width: 24px;
    height: 24px;
    margin-top: 0;
    float: none;
    outline: none;
    box-shadow: none;
    &:checked{
      background-color: transparent;
      border-color: rgba(61, 37, 112, 0.3);
    }
    &:checked[type=checkbox] {
      background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='0.5' y='0.5' width='23' height='23' rx='3.5' stroke-opacity='0.3'/%3E%3Cpath d='M6.75 12.25L10.5 16L17.25 9.25' stroke='%233D2570' stroke-width='2.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
    }
  }
  label{
    margin-left: 14px;
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 20px;
    color: $theme-purple;
  }
}
/**
* switch checkbox
**/
.checkBox-Group{
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: space-between;
  align-items: center;
  label{
    margin-bottom: 0;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: $theme-black;
  }
  .form-check-input{
    background-color: $checkboxBg;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%23fff'/%3E%3C/svg%3E");
    width: 44px;
    height: 24px;
    cursor: pointer;
    &:focus, &:active{
      outline: none;
      box-shadow: none;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%23fff'/%3E%3C/svg%3E");
    }
    &:checked[type=checkbox]{
      background-color: $theme-purple;

    }
  }
}
/*
* Label Based Checkboxes
*/
.labelCheckbox{
  padding-left: 0;
  margin-bottom: 10px;
  input{
    display: none;
  }
  input[type=checkbox]:checked ~ .form-check-label{
    background-color: $theme-purple;
    color: $theme-white;
  }
  .form-check-label{
    width: 100%;
    background: $theme-lpurple;
    padding: 6px 16px;
    border-radius: 5px;
    cursor: pointer;
  }
}
/**
* Password group
**/
.passwordGroup{
  position: relative;
  overflow: hidden;
  //margin-bottom: 13px;
  .form-control{
    width: 100%;
    font-size: 15px;
    line-height: 24px;
    padding-right: 50px;
  }
  .btn{
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    border: none;
    box-shadow: none;
    padding: 0 15px;
    font-size: 20px;
    background: none;
    z-index: 99;
    color: $theme-purple;
    opacity: 0.5;
    &:hover, &:focus{
      border: none;
      box-shadow: none;
      //padding: 0;
      opacity: 1;
      background: none;
    }
  }
}
/**
* file upload as label
**/
.LabelFile{
  position: relative;
  overflow: hidden;
  input{
    display: none;
  }
  label{
    font-weight: 700;
    font-size: 15px;
    line-height: 22px;
    color: $theme-purple;
    cursor: Pointer;

  }
}
/*
* Tags Input container
*/
.TagsContainer{
  background: $theme-lpurple;
  border-radius: 6px 6px 0px 0px;
  border-bottom: 1px solid rgba(61, 37, 112, 0.3);
  padding: 11px;
  min-height: 100px;
  .ReactTags__tagInputField{
    outline: none;
    box-shadow: none;
    background: none;
    border: none;
    width: 100%;
    margin-top: 5px;
  }
  .ReactTags__selected{
    .ReactTags__tag{
      background: rgba(61, 37, 112, 0.07);
      border-radius: 100px;
      font-family: "Poppins", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      color: $theme-purple;
      padding: 2.5px 10px;
      margin-right: 5px;
      button{
        border: none;
        outline: none;
        background: none;
        padding: 0;
        font-size: 16px;
        margin-left: 5px;
      }
    }
  }
}
/*
* Dashboard Notifications
*/
.AccountNotification{
  background: $theme-lpurple;
  border-radius: 6px;
  width: 100%;
  padding: 13px 20px;
  span{
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: $theme-purple;
  }
  svg{
    margin-right: 12px;
  }
  b{
    font-weight: 700;
  }
  .inlineLink{
    color: $theme-purple;

  }
}
/*
* read more component
*/
.read-or-hide{
  font-weight: 600;
  color: $theme-purple;
  cursor: pointer;
}

/*
* Custom Modal
*/
.customModal{
  .modal-content{
    .modal-header{
      padding-bottom: 5px;
      border-bottom: none;
      .modal-title{
        font-weight: 700;
        font-size: 22px;
        line-height: 110%;
        letter-spacing: -0.02em;
        color: $theme-black;

      }
      .btn-close{
        background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M17.745 15.645L18.24 15.15L17.745 14.655L15.0899 12L17.745 9.34498L18.24 8.85L17.745 8.35503L15.645 6.25503L15.15 5.76005L14.655 6.25503L12 8.91005L9.34498 6.25503L8.85 5.76005L8.35503 6.25503L6.25503 8.35503L5.76005 8.85L6.25503 9.34498L8.91005 12L6.25503 14.655L5.76005 15.15L6.25503 15.645L8.35503 17.745L8.85 18.24L9.34498 17.745L12 15.0899L14.655 17.745L15.15 18.24L15.645 17.745L17.745 15.645ZM0.7 12C0.7 5.7866 5.7866 0.7 12 0.7C18.2134 0.7 23.3 5.7866 23.3 12C23.3 18.2134 18.2134 23.3 12 23.3C5.7866 23.3 0.7 18.2134 0.7 12Z' fill='%234E4F70' stroke='%234E4F70' stroke-width='1.4'/%3E%3C/svg%3E%0A");
        box-shadow: none;
        outline: none;
      }
    }
    .modal-body{
      p{
        font-weight: 400;
        font-size: 16px;
        line-height: 160%;
        color: $theme-black;
      }
    }
  }
}

/*
* Error 404 Text
*/
.error404Text{
  font-size: 100px !important;
  font-weight: 700;
  color: $theme-purple;
  text-shadow: 12px 8px 10px #d1caca;
}