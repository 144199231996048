/*
**********************************************
* Signin styles
**********************************************
*/
.AuthLayout{
  position: relative;
  overflow: hidden;
  .authenticationLayout{
    width: 100vw;
    height: 100vh;
    position: relative;
    display: flex;
    flex-direction: row;
    /**
    * left panel
    **/
    .left{
      width: 50vw;
      background: $theme-white;
      overflow: scroll;
      &::-webkit-scrollbar {
        // width: 3px;
        display: none;
      }

      &::-webkit-scrollbar-track {
        background: $theme-lpurple;
      }

      &::-webkit-scrollbar-thumb {
        background: $theme-purple;
      }

      &::-webkit-scrollbar-thumb:hover {
        background: $theme-purple;
      }
      h1{
        font-weight: 700;
        font-size: 30px;
        line-height: 110%;
        letter-spacing: -0.02em;
      }
      p{
        font-weight: 400;
        font-size: 16px;
        line-height: 130%;
      }
      .logoContainer{
        padding: 24px 32px;
        border-bottom: 1px solid $theme-lpurple;
      }
      .componentContainer{
        margin-top: 64px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    /**
    * right panel
    **/
    .right{
      width: 50vw;
      height: 100%;
      background: $theme-lpurple;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  /**
  * login form width setting
  **/
  .loginForm{
    min-width: 370px;
  }
  /**
  * link - forgot password
  **/
  .linkNav{
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 20px;
    color: $theme-purple;
    text-decoration: none;
  }
  .list-login{
    position: relative;
    margin-top: 16px;
  }
  .hr-short{
    height: 1px;
    max-width: 140px;
    margin: 20px auto;
    text-align: center;
    opacity: 1;
    background: $theme-lpurple;
  }
  .p-account{
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    text-align: center;
    color: $theme-black;
    a{
      font-weight: 700;
      text-decoration: none;
    }
    &.boxed-account{
      padding: 10px 39.5px;
      background: $theme-lpurple;
      border-radius: 50px;

    }
  }
  /**
  * Create Account
  **/
  h3{
    font-weight: 700;
    font-size: 19px;
    line-height: 110%;
    letter-spacing: -0.02em;
    color: $theme-black;
  }
  /**
  * share code buttons
  **/
  .boxSendCode{
    background: #FFFFFF;
    border: 1px solid rgba(61, 37, 112, 0.1);
    border-radius: 10px;
    //box-sizing: border-box;
    //width: 100%;
    padding: 25px 20px;
    display: flex;
    align-items: center;
    margin-bottom: 12px;
    cursor: pointer;
    transition: all 0.5s ease;
    .Icon{
      margin-right: 17px;
    }
    .Text{
      span{
        display: block;
        &:nth-child(1){
          font-weight: 600;
          font-size: 16px;
          line-height: 24px;
          color: $theme-black;
        }
        &:nth-child(2){
          font-weight: 400;
          font-size: 12px;
          line-height: 18px;
          color: $theme-black;
          opacity: 0.7;
          max-width: 271px;

          overflow: hidden;


        }
      }
    }
    &:hover, &:focus{
      border: 1px solid rgba(61, 37, 112, 0.5);
      transition: all 0.5s ease;
    }
  }
  .OtpInput-container{
    border-top: 1px solid $theme-lpurple;
    border-bottom: 1px solid $theme-lpurple;
    padding-top: 10px;
    padding-bottom: 10px;

    div{
      &:first-child{
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .OtpInput{
        input{
          width: 60px!important;
          height: 60px;
          font-weight: 600;
          font-size: 36px;
          line-height: 54px;
          color: $theme-purple;
          margin-right: 5px;
          border: none;
          &::placeholder{
            color: $theme-purple;
          }
        }
      }
    }
  }
}