/*
**********************************************
* Default theme Colors
**********************************************
*/

$red-notification-color  : #EC1246;
$theme-purple            : #3D2570;
$theme-lpurple           : #F5F4F8;
$theme-black             : #060836;
$header-bg-color         : #ffffff;
$theme-white             : #ffffff;
$checkboxBg               : #C5BED4;
$green-Success            : #12B76A;
$green-Success-light      : rgba(18, 183, 106, 0.4);
$theme-red                : #E14339;
$theme-orange              : #EA7317;
$green-active               : #12B76A;


