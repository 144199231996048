/*
**********************************************
* navigation
**********************************************
*/
.ourChatNav{
  padding: 15px 20px;
  background-color: $theme-white !important;
  border-bottom: 1px solid $theme-lpurple;
  .navbar-nav{
    margin-left: 40px;
    .nav-link{
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: $theme-black;
      transition: all 0.5s ease;
      &:hover, &:focus, &.active{
        color: $theme-purple;
        transition: all 0.5s ease;
      }
    }
  }
  &.ProfessionalNav{
    .navbar-nav{
      .nav-link{
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        color: $theme-black;
        svg{
          margin-right: 8px;
          transform: translateY(-2px);
        }
      }
    }
    .profileStats{
      font-weight: 700;
      font-size: 16px;
      line-height: 110%;
      letter-spacing: -0.02em;
      color: $theme-white;
      background: $theme-purple;
      border-radius: 100px;
      //max-height: 40px;
      padding: 5px 10px 5px 6px;
      display: flex;
      flex-wrap: nowrap;
      align-content: center;
      align-items: center;

      padding: 5px 10px;
      svg{
        margin-right: 5px;
      }

    }
    .ProfileDropdown{
      a{
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        color: $theme-black;

      }
        img.ProfileIcon{
          width: 40px;
          height: 40px;
          object-fit: cover;
          object-position: center;
          margin-right: 12px;
        }
      .dropdown-menu{
        min-width: 11rem;
        padding: 0.5rem 0;
        border: 1px solid rgba(61, 37, 112, 0.3);
        box-shadow: 0px 0px 27px rgba(6, 8, 54, 0.1);
        border-radius: 10px;
        hr{
          margin-left: 10px;
          margin-right: 10px;
          background-color: aliceblue;
        }
        .dropdown-item{
          margin-bottom: 5px;
          font-weight: 500;
          font-size: 15px;
          line-height: 150%;
          color: $theme-black;

          svg{
            margin-right: 12px;
          }
          &:hover, &:focus, &.active{
            background-color: transparent;
          }
          &:last-child{
            color: $theme-red;
          }
        }
      }
    }
  }
}