/************************************************
https://www.hobo-web.co.uk/best-screen-size/
*************************************************
Desktop
*************************************************
------------------------------   Banner
1	  2560×1440	    (7.41%)    |     ✓
2	  1920×1080	    (22.62%)   |     ✓
3	  1680×1050	    (4.28%)    |     ✓
4	  1792×1120	    (2.06%)    |     ✓
5 	1600×900      (1.87%)    |     ✓
6	  1536×864	    (8.60%)    |     ✓
7	  1440×900	    (9.55%)    |     ✓
8	  1366×768	    (11.20%)   |     ✓
9	  1280×800	    (2.33%)    |     ✓
10  1280×720	    (3.55%)    |     ✓

*************************************************
Tablets
*************************************************
768×1024 – 48.64%
1280×800 – 7.55%
800×1280 – 7.44%
601×962  – 4.5%
810×1080 – 4.41%
600×1024 – 2.95%

*************************************************
Mobile
*************************************************
414×816 – 14.51%
375×667 – 12.47%
375×812 – 7.21%
360×640 – 5.43%
412×915 – 5.18%
360×780 – 5.04%

***************************************************
sass --watch style.scss:style.css --style compressed
**************************************************/
@media (max-width: 2560px) {}
@media (max-width: 1920px) {}
@media (max-width: 1600px) {}
@media (max-width: 1440px) {}
@media (max-width: 1367px) {}
@media (max-width: 1280px) {}
@media (max-width: 1200px) {}
@media (max-width: 1024px) {}
@media (max-width: 991.98px) {}
@media (max-width: 769.98px) {}
@media (max-width: 575.98px) {}
@media (max-width: 320px) {}


@media (min-width: 576px){
  .modal-sm {
    max-width: 365px;
  }
}
